import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

export const Image = ({ image, odd, first }) => {
  const passedImage = getImage(image);
  return (
    <>
      <div className={`mb-16 md:p-20 ${odd ? "col-start-2" : ""}`}>
        <GatsbyImage
          className={`${first ? "md:mt-0" : "md:-mt-64"}`}
          image={passedImage}
        />
      </div>
      <div></div>
    </>
  );
};

export default Image;
