import * as React from "react";
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../components/layout";
import Image from "../components/image";
import Seo from "../components/seo";

const serializers = {
  types: {
    block: (props) => <p className="mb-2">{props.children}</p>,
  },
};

// markup
const ProjectPage = ({
  data: {
    sanityProject: {
      title,
      featurePhoto,
      location,
      description,
      _rawDescriptionLong,
      builder,
      architect,
      design,
      photo,
      images,
    },
  },
}) => {
  const featureImage = getImage(featurePhoto.asset);
  return (
    <Layout>
      <Seo
        title={`SCR Landscapes | Projects | ${title}`}
        description={description}
      />
      <div className="md:grid md:grid-cols-2 md:grid-flow-row-dense">
        <GatsbyImage className="h-screen" image={featureImage} />
        <section className={`p-20 flex flex-col justify-center`}>
          <h2 className="text-3xl leading-6 font-medium text-gray-50">
            {title}
          </h2>
          <p className="mt-2 text-base text-gray-300">
            Location: <span className="text-gray-400">{location}</span>
          </p>
          <p className="text-base text-gray-300">
            Builder: <span className="text-gray-400">{builder}</span>
          </p>
          <p className="text-base text-gray-300">
            Architect: <span className="text-gray-400">{architect}</span>
          </p>
          <p className="text-base text-gray-300">
            Design: <span className="text-gray-400">{design}</span>
          </p>
          {photo && (
            <p className="text-base text-gray-300">
              Photographer: <span className="text-gray-400">{photo}</span>
            </p>
          )}
          <BlockContent
            className="mt-4 text-base text-gray-50"
            blocks={_rawDescriptionLong}
            serializers={serializers}
          />
        </section>
        {images.map((image, i) => {
          return (
            <Image
              image={image.asset}
              key={image.asset._key}
              odd={i % 2 === 0}
              first={i === 0}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SanityProjectQuery($id: String!) {
    sanityProject(id: { eq: $id }) {
      title
      design
      description
      _rawDescriptionLong
      builder
      architect
      photo
      location
      images {
        asset {
          _key
          gatsbyImageData(width: 1300, placeholder: BLURRED)
        }
      }
      featurePhoto {
        asset {
          gatsbyImageData(width: 1300, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default ProjectPage;
